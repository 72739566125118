// Import the Google Font
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

$tennis-bg-dark: #002f54;
$tennis-bg-light: #005381;
$tennis-bg-very-light: #5a9bbd;
$tennis-color: #fff;
$tennis-ball-color: #e4e235;
$tennis-ball-color-dark: #aeac4c;

$input-disabled-bg: #999;

// override bootstrap variables
$body-bg: #fff;
$body-color: #000;
$link-color: $tennis-ball-color;
$link-hover-color: $tennis-ball-color-dark;
$font-family-base: 'Poppins', sans-serif;
$enable-rounded: false;

body {
    font-family: $font-family-base;
}

@media (max-width: 768px) {
    body {
      font-size: 12px !important; // Font size for small screens (up to 786px)
    }
}

.tennis-primary, .tennis-secondary {
    @extend .p-3;
    @extend .p-sm-5;
}

.tennis-header {
    background-image: url('./tennis-ball-logo.png');
    background-repeat: no-repeat;
    background-position: right 60%;
    background-size: 25%;
    h1 { 
        text-transform: uppercase; 
        font-weight: bold;
    }
}

h2 {
    @extend .pb-3;
    text-transform: uppercase;
}

.tennis-primary {
    background-color: $tennis-bg-dark;
    color: $tennis-color;
}

.tennis-secondary {
    background-color: $tennis-bg-light;
    color: $tennis-color;
}

.startButton {
    background-color: $tennis-ball-color !important;
    &:hover {
        background-color: $tennis-ball-color-dark !important;
    }
}

.tennis-ball-color {
    color: $tennis-ball-color;
}

.tennis-odds .row {
    @extend .py-2;
    &.table-header {
        font-weight: bold;
    }
    .form-label {
        @extend .pt-2;
    }
    .slider-selection-info {
        margin-top: 8px;
    }
}

.horizontal-slider {
    .slider-track {
        height: 8px;
        top: 16px;
        background-color: rgba(255,255,255,0.1);
        &.slider-track-0 {
            background-color: rgba(255,255,255,0.5);
        }
    }
    .slider-mark {
        width: 12px;
        height: 12px;
        top: 14px;
        background-color: rgba(255,255,255,0.3);
        border-radius: 50%;
        margin-left: 14px;
    }    
    .tennis-ball-thumb {
        background-color: #fff;
        color: #000;
        border-radius: 50%;
        text-align: center;
        height: 40px;
        width: 40px;
        line-height: 40px;
        cursor: ew-resize;
        font-size: 12px;
        user-select: none;
        &.active {
            box-shadow: 0 0 5px $tennis-ball-color;
        }
    }
    &.disabled {
        .slider-track {
            background-color: #000;
        }
        .tennis-ball-thumb {
            background-color: $input-disabled-bg;
            cursor: not-allowed;
        }
    }
}

table.single-match-result.in-progress tr.last-point-winner td.points-won-in-game {
    background-color: $tennis-ball-color;
}

.tennis-stats table th {
    background-color: $tennis-bg-light;
    border-bottom: 3px solid $tennis-bg-light;
    color: #fff;
}

.form-select:disabled, .form-control:disabled {
    background-color: $input-disabled-bg;
    cursor: not-allowed;
}

.form-check-input:disabled {
    cursor: not-allowed;
}

// Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap";